<template>
  <v-card>
    <v-toolbar
      dark
      color="primary"
      dense
      class="mb-5"
    >
      <v-btn
        icon
        dark
        @click="close"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-toolbar-title>Unidades de Atendimento</v-toolbar-title>
      <v-spacer />
    </v-toolbar>

    <v-form
      ref="form"
    >
      <v-row
        justify="start"
        no-gutters
        class="pa-2 mt-0"
      >
        <v-col
          class="d-flex pr-5"
          lg="6"
          md="12"
          sm="12"
        >
          <v-text-field
            v-model="units.title"
            :rules="$rules.required"
            required
            outlined
            dense
            label="Título *"
          />
        </v-col>

        <v-col
          class="d-flex pr-5"
          lg="6"
          md="12"
          sm="12"
        >
          <v-text-field
            v-model="units.address"
            :rules="$rules.required"
            required
            label="Endereço *"
            dense
            outlined
          />
        </v-col>
      </v-row>

      <v-row
        justify="center"
        no-gutters
        class="pa-2 mt-0"
      >
        <v-col
          class="d-flex pr-5"
          lg="6"
          md="12"
          sm="12"
        >
          <v-text-field
            v-model="units.primaryPhone"
            :counter="11"
            outlined
            dense
            required
            label="Telefone *"
            :rules="$rules.required"
          />
        </v-col>

        <v-col
          class="d-flex pr-5"
          lg="6"
          md="12"
          sm="12"
        >
          <v-text-field
            v-model="units.secondaryPhone"
            outlined
            dense
            required
            label="Telefone Secundário *"
          />
        </v-col>
      </v-row>

      <v-row
        justify="center"
        no-gutters
        class="pa-2 mt-0"
      >
        <v-col
          class="d-flex pr-5"
          lg="6"
          md="12"
          sm="12"
        >
          <v-autocomplete
            v-model="units.category"
            :rules="$rules.required"
            :items="categories"
            item-text="title"
            item-value="id"
            outlined
            dense
            required
            label="Selecione a Categoria *"
          />
        </v-col>

        <v-col
          class="d-flex pr-5"
          lg="6"
          md="12"
          sm="12"
        >
          <v-autocomplete
            v-model="units.city"
            :rules="$rules.required"
            :items="cities"
            item-text="city"
            item-value="id"
            outlined
            dense
            required
            label="Selecione a Cidade *"
          />
        </v-col>
      </v-row>

      <v-row
        justify="center"
        no-gutters
        class="pa-2 mt-0"
      >
        <v-col
          class="d-flex pr-5"
          lg="6"
          md="12"
          sm="12"
        >
          <v-text-field
            v-model="units.linkMaps"
            :rules="$rules.required"
            outlined
            dense
            required
            label="Link Google Maps *"
          />
        </v-col>
      </v-row>
      <v-row
        justify="center"
        no-gutters
        class="pa-2 mt-0"
      >
        <v-col
          cols="12"
          sm="6"
        >
          <v-select
            v-model="units.targetProd"
            :items="targetProducts"
            chips
            label="Produtos Alvos da Campanha"
            item-text="title"
            return-object
            multiple
            outlined
          />
        </v-col>
      </v-row>
      <v-row
        justify="start"
        no-gutters
        class="pa-2 mt-0"
      >
        <v-col
          class="pr-5"
        >
          <kosmo-editor
            v-model="units.text"
            label="Texto *"
            required
            :rules="$rules.required"
          />
        </v-col>
      </v-row>
    </v-form>
    <v-row
      class="mt-10"
      justify="center"
      no-gutters
    >
      <v-card-actions>
        <v-btn
          color="secondary"
          @click="close"
        >
          Cancelar
        </v-btn>
        <v-btn
          color="primary"
          :loading="isLoading"
          @click="submit"
        >
          Salvar
        </v-btn>
      </v-card-actions>
    </v-row>
  </v-card>
</template>

<script>

import UnitCategoryService from '../../../services/unit-category.service';
import UnitCityService from '../../../services/units-city.service';
import UnitService from '../../../services/unit.service';
import TargetProductService from '../../../services/target-product.service';

export default {
  name: 'UnitsForm',
  props: {
    populateWith: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      units: {},
      cities: [],
      categories: [],
      targetProducts: [],
      isLoading: false,
    };
  },
  async created() {
    try {
      this.targetProducts = await TargetProductService.findAll();
      this.categories = await UnitCategoryService.findAll();
      this.cities = await UnitCityService.findAll();

      if (this.populateWith.id) {
        this.units = this.populateWith;
      }
    } catch (e) {
      this.$handleHttpError(e);
    }
  },
  methods: {
    close() {
      this.$emit('close-dialog');
    },

    async createUnit(unit) {
      try {
        const data = await UnitService.create(unit);
        this.$toast.success('Unidade de Atendimento salva com sucesso');
        return data;
      } catch (e) {
        this.$handleHttpError(e);
      }
    },

    async updateUnit(unit) {
      try {
        const data = await UnitService.update(unit);
        this.$toast.success('Unidade de Atendimento salva com sucesso');
        return data;
      } catch (e) {
        this.$handleHttpError(e);
      }
    },

    async submit() {
      this.isLoading = true;
      if (!this.$refs.form.validate()) return;

      const unit = {
        ...this.units,
      };

      if (unit.id) {
        const editUnit = await this.updateUnit(unit);
        this.$emit('unit-edited', editUnit);
        this.isLoading = false;
        this.close();
      } else {
        const addUnit = await this.createUnit(unit);
        this.isLoading = false;
        this.$emit('unit-added', addUnit);
        this.$refs.form.reset();
        this.$refs.form.resetValidation();
      }
    },
  },
};
</script>
