import api from './api';

const url = 'service-unity-category';

const create = (category) => api.post(url, category);

const findAll = () => api.get(url);

const update = (category) => api.put(url, category);

const deleteOne = (id) => api.delete(`${url}/${id}`);

export default {
  create, findAll, update, deleteOne,
};
